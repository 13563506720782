import { Injectable } from "@angular/core";
import { IBaseEntity, IIssueEntity, IProjectEntity } from "@common/interfaces/base";
import { IEntityInteraction } from "@common/interfaces/entityInteraction";
import { ID } from "@common/interfaces/id";
import { IIssue } from "@common/interfaces/issue";
import { RoleNames } from "@common/interfaces/permissions";
import { IProject } from "@common/interfaces/project";
import { IProjectScope } from "@common/interfaces/projectScope";
import { Topics } from "@common/interfaces/topics";
import { ObjectHelpers } from "@common/utils/object.helpers";
import { EntityStoreAction, runEntityStoreAction, transaction } from '@datorama/akita';
import { SocketIoService } from '@ep-om/core/services/socket-io.service';
import { compareDateString } from "@ep-om/utils/date";
import { BehaviorSubject } from "rxjs";
import { filter, switchMap, tap } from 'rxjs/operators';
import { AuthQuery } from "../auth/auth.query";
import { AuthStore } from "../auth/auth.store";
import { AppointmentService } from "./appointment/appointment.service";
import { EntityInteractionQuery } from "./entityInteraction/entityInteraction.query";
import { IssueQuery } from "./issue/issue.query";
import { IssueService } from "./issue/issue.service";
import { IssueStore } from "./issue/issue.store";
import { LastUpdateQuery } from './lastUpdate/lastUpdate.query';
import { LastUpdateService } from './lastUpdate/lastUpdate.service';
import { ProjectQuery } from './project/project.query';
import { ProjectService } from "./project/project.service";
import { ProjectScopeQuery } from "./projectScope/projectScope.query";
import { UserService } from "./user/user.service";
import { WorkflowQuery } from "./workflow/workflow.query";

export abstract class UpdateStoreStrategy {
  abstract start(topic: Topics, _store: string): void;
  abstract updateStore(...args: any[]): void;
  public firstSync$ = new BehaviorSubject({});

  @transaction()
  protected persistUpdates<T extends IBaseEntity>(updates: { topic: string, data: T[] }, storeName: string): T[] {
    const toBeDeleted = updates.data.reduce((acc, d) => { if (!!d.deletedAt || !!d.archivedAt) { acc.push(d.id) } return acc; }, []);
    const toBeUpserted = updates.data.filter(d => !d.deletedAt);
    if (toBeUpserted.length > 0) {
      runEntityStoreAction(storeName, EntityStoreAction.UpsertManyEntities, upsertManyEntities => upsertManyEntities(toBeUpserted));
    }
    if (toBeDeleted.length > 0) {
      runEntityStoreAction(storeName, EntityStoreAction.RemoveEntities, removeEntities => removeEntities(toBeDeleted));
    }
    return toBeUpserted;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectEntityStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService
  ) { super() }
  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => this.projectQuery.activeId$.pipe(
        filter(projectId => !!projectId),
        tap(() => { this.firstSync$.next({ ...this.firstSync$.value, [topic]: false }) }),
        switchMap((projectId: string) => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          return this.socketIoService.listenEntity<IProjectEntity>(topic, { projectId, updatedAt: lastUpdates[`PRJ_${projectId}_${topic}`] })
        }),
      )),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe((updates) => {
      this.updateStore(topic, updates)
      if (!this.firstSync$.value[topic]) {
        this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
      }
    })
  }

  updateStore(topic: Topics, updates: { topic: Topics, data: IProjectEntity[] }) {
    this.persistUpdates(updates, topic);
    if (!this.firstSync$.value[topic]) {
      this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
    }
    const last = updates.data.length - 1;
    const lastItem = updates.data[last];
    this.lastUpdateService.setChildLastUpdate(lastItem?.projectId, updates.topic, lastItem?.updatedAt);
  }
}

@Injectable({
  providedIn: 'root',
})
export class EntityInteractionStoreStrategy extends ProjectEntityStoreStrategy {
  constructor(
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService,
    protected issueService: IssueService,
    protected authQuery: AuthQuery,
  ) {
    super(projectQuery, lastUpdateQuery, lastUpdateService, socketIoService)
  }

  updateStore(topic: Topics, updates: { topic: Topics, data: IEntityInteraction[] }) {
    super.updateStore(topic, updates);
  }
}

@Injectable({
  providedIn: 'root'
})
export class IssueStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected issueStore: IssueStore,
    protected issueQuery: IssueQuery,
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService,
    protected entityInteractionQuery: EntityInteractionQuery,
    protected authQuery: AuthQuery,
  ) { super() }

  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => this.projectQuery.activeId$.pipe(
        filter(projectId => !!projectId),
        tap(() => { this.firstSync$.next({ ...this.firstSync$.value, [topic]: false }) }),
        switchMap((projectId: string) => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          return this.socketIoService.listenEntity<IIssue>(topic, { projectId, updatedAt: lastUpdates[`PRJ_${projectId}_${topic}`] })
        }),
      )),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(updates => {
      if (updates && updates.data && updates.data.length > 0) {
        this.updateStore(topic, updates);
      }
      if (!this.firstSync$.value[topic] && updates.data.length < 500) { //backend sends in bucket of 500 items
        this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
      }
    });
  }


  updateStore(topic: Topics, updates: { topic: Topics, data: IIssue[] }) {
    const toBeUpdated = this.persistUpdates(updates, topic);
    const last = updates.data.length - 1;
    const lastItem = updates.data[last];
    this.lastUpdateService.setChildLastUpdate(lastItem.projectId, updates.topic, lastItem.updatedAt);
  }

}
@Injectable({
  providedIn: 'root',
})
export class IssueEntityStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected projectQuery: ProjectQuery,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected issueService: IssueService,
    protected socketIoService: SocketIoService
  ) { super() }
  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => this.projectQuery.activeId$.pipe(
        filter(projectId => !!projectId),
        tap(() => { this.firstSync$.next({ ...this.firstSync$.value, [topic]: false }) }),
        switchMap((projectId: string) => {
          const lastUpdates = this.lastUpdateQuery.getValue();
          return this.socketIoService.listenEntity<IIssueEntity>(topic, { projectId, updatedAt: lastUpdates[`PRJ_${projectId}_${topic}`] })
        }),
      )),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(updates => {
      if (updates && updates.data && updates.data.length > 0) {
        this.updateStore(topic, updates);
      }
      if (!this.firstSync$.value[topic] && updates.data.length > 499) {
        this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
      }
    });
  }


  updateStore(topic: Topics, updates: { topic: Topics, data: IIssueEntity[] }) {
    const updatedEntities = this.persistUpdates(updates, topic);
    const last = updates.data.length - 1;
    const lastItem = updates.data[last];
    this.lastUpdateService.setChildLastUpdate(lastItem.projectId, updates.topic, lastItem.updatedAt);
  }
}

@Injectable({
  providedIn: 'any',
})
export class BaseEntityStoreStrategy extends UpdateStoreStrategy {
  constructor(
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService
  ) { super() }

  start(topic: Topics) {
    this.socketIoService.uponConnection$.pipe(
      switchMap(() => {
        const lastUpdates = this.lastUpdateQuery.getValue();
        return this.socketIoService.listenEntity<IBaseEntity>(topic, { updatedAt: lastUpdates[topic] })
      }),
      this.socketIoService.stopOnDisconnectionPipe(),
      this.socketIoService.repeatOnConnectionPipe(),
    ).subscribe(
      updates => {
        if (updates && updates.data && updates.data.length > 0) {
          this.updateStore(topic, updates);
        }
        if (!this.firstSync$.value[topic]) {
          this.firstSync$.next({ ...this.firstSync$.value, [topic]: true });
        }
      }
    )
  }
  updateStore(topic: Topics, updates: { topic: Topics, data: IBaseEntity[] }) {
    this.persistUpdates(updates, topic);
    const lastItem = updates.data.at(-1);
    this.lastUpdateService.setLastUpdate(updates.topic, lastItem.updatedAt);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectStoreStrategy extends BaseEntityStoreStrategy {
  visibilityManager: VisibilityManager;
  constructor(
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected socketIoService: SocketIoService,
    protected projectQuery: ProjectQuery,
    private wfQuery: WorkflowQuery,
    projectScopeQuery: ProjectScopeQuery,
    authQuery: AuthQuery,
    appointmentService: AppointmentService,
  ) {
    super(
      lastUpdateQuery,
      lastUpdateService,
      socketIoService
    );
    this.visibilityManager = new VisibilityManager(wfQuery, projectScopeQuery, authQuery, projectQuery, appointmentService,);
  }

  updateStore(topic: Topics, updates: { topic: Topics, data: IProject[] }) {
    this.visibilityManager
      .calculateStartingRoles()
      .calculateStartingResourcePerRoles();

    this.persistUpdates(updates, topic);
    const lastItem = updates.data.at(-1);
    this.lastUpdateService.setLastUpdate(updates.topic, lastItem.updatedAt);

    this.visibilityManager
      .calculateCurrentRoles()
      .calculateCurrentResourcePerRoles()
      .cleanUpAppointmentByUsers()
      .retrieveMissingAppointmentByUsers()
  }

}


@Injectable({
  providedIn: 'root'
})
export class ProjectScopeStoreStrategy extends BaseEntityStoreStrategy {
  visibilityManager: VisibilityManager;
  constructor(
    protected socketIoService: SocketIoService,
    protected lastUpdateQuery: LastUpdateQuery,
    protected lastUpdateService: LastUpdateService,
    protected projectService: ProjectService,
    protected issueService: IssueService,
    protected authStore: AuthStore,
    protected authQuery: AuthQuery,
    protected userService: UserService,
    protected projectScopeQuery: ProjectScopeQuery,
    protected wfQuery: WorkflowQuery,
    projectQuery: ProjectQuery,
    appointmentService: AppointmentService
  ) {
    super(
      lastUpdateQuery,
      lastUpdateService,
      socketIoService
    );
    this.visibilityManager = new VisibilityManager(wfQuery, projectScopeQuery, authQuery, projectQuery, appointmentService, userService, issueService, projectService)
  }

  updateStore(topic: Topics, updates: { topic: Topics, data: IProjectScope[] }) {
    this.visibilityManager
      .calculateStartingRoles()
      .calculateStartingResourcePerRoles();
      
    console.log('[PROJECT_SCOPE] update')
    console.log('[PROJECT_SCOPE] old ProjectRoles', this.authQuery.getValue()?.projectRole);

    this.persistUpdates(updates, topic);

    const newProjectRoles = this.projectScopeQuery.getAll().reduce((acc, projectScope) => {
      const currentUserRole = projectScope?.users?.find(user => user.id === this.authQuery.getLoggedUserId());
      if (!currentUserRole) {
        return acc;
      }
      acc[projectScope.projectId] = currentUserRole.roles;
      return acc;
    }, {});

    console.log('[PROJECT_SCOPE] new ProjectRoles', newProjectRoles);
    this.authStore.update(state => ({
      ...state,
      projectRole: newProjectRoles
    }));

    const lastItem = updates.data.at(-1);
    this.lastUpdateService.setLastUpdate(updates.topic, lastItem.updatedAt);
    this.visibilityManager
      .calculateCurrentRoles()
      .calculateCurrentResourcePerRoles()
      .cleanUpAppointmentByUsers()
      .retrieveMissingAppointmentByUsers()
      .manageLimitedVisibility();
  }

}

@Injectable({
  providedIn: 'root',
})
export class DummyStoreStrategy extends UpdateStoreStrategy {
  start() { }
  updateStore() { }
}


class VisibilityManager {
  loggedUserId: string;
  prevRoles: { [key: string]: RoleNames[] };
  prevResourceRoles: RoleNames[];
  prevUserPerResourceRole: string[];
  /**
   * key are project id
   */
  currentRoles: { [key: string]: RoleNames[] };
  currentResourceRoles: RoleNames[];
  currentUserPerResourceRole: string[];


  constructor(
    private wfQuery: WorkflowQuery,
    private projectScopeQuery: ProjectScopeQuery,
    private authQuery: AuthQuery,
    private projectQuery: ProjectQuery,
    private appointmentService?: AppointmentService,
    private userService?: UserService,
    private issueService?: IssueService,
    private projectService?: ProjectService,
  ) {

  }

  calculateStartingRoles() {
    this.loggedUserId = this.authQuery.getLoggedUserId();
    this.prevRoles = this.authQuery.getProjectRoles();
    this.prevResourceRoles = this._getResourceRoles(this.prevRoles);
    return this;
  }

  calculateStartingResourcePerRoles() {
    this.prevUserPerResourceRole = this._getUserPerResourceRole(this.prevResourceRoles)
    return this;
  }

  calculateCurrentRoles() {
    this.currentRoles = this.authQuery.getProjectRoles();
    this.currentResourceRoles = this._getResourceRoles(this.currentRoles);
    return this;
  }

  calculateCurrentResourcePerRoles() {
    this.currentUserPerResourceRole = this._getUserPerResourceRole(this.currentResourceRoles);
    return this;
  }

  cleanUpAppointmentByUsers() {
    const personsToRemove = this.prevUserPerResourceRole.filter(user => !this.currentUserPerResourceRole.includes(user));
    this.appointmentService.localRemoveByUserId(personsToRemove);
    return this;
  }

  retrieveMissingAppointmentByUsers() {
    const personsToAdd = this.currentUserPerResourceRole.filter(user => !this.prevUserPerResourceRole.includes(user));
    if (personsToAdd.length > 0) {
      this.appointmentService.remoteGet({ users: personsToAdd });
    }
    return this;
  }

  manageLimitedVisibility() {
    const changes = this._calculateRoleModifications();
    console.log('role changes:', changes);

    for (const [project, { addedRoles, removedRoles }] of Object.entries(changes)) {
      if (
        addedRoles?.some(role => ['CompanyLimited', 'Limited'].includes(role))
        || removedRoles?.some(role => ['CompanyLimited', 'Limited'].includes(role))
      ) {
        this.projectService.reloadProject(project);
      }
    }
  }

  private _calculateRoleModifications(): { [project: string]: { addedRoles: RoleNames[], removedRoles: RoleNames[] } } {
    const calculate = (setRoles1: { [project: string]: RoleNames[] }, setRoles2: { [project: string]: RoleNames[] }): { [key: string]: Set<RoleNames> } => {
      let result: { [key: string]: Set<RoleNames> } = {}
      for (const [project, roles] of Object.entries(setRoles1)) {
        if (!setRoles2[project]) {
          roles.forEach(role => result = {
            ...result,
            [`${project}`]: result[project]?.add(role) || new Set([role]),
          });
          continue;
        }
        for (const currentRole of roles) {
          if (!setRoles2[project].some(role => role === currentRole)) {
            result = {
              ...result,
              [`${project}`]: result[project]?.add(currentRole) || new Set([currentRole]),
            }
          }
        }
      }
      return result;
    }
    let addedRoles = calculate(this.currentRoles || {}, this.prevRoles || {});
    let removedRoles = calculate(this.prevRoles || {}, this.currentRoles || {});
    let result = {}
    Object.entries(addedRoles).forEach(([project, roles]) => result[project] = { ...result[project], addedRoles: [...roles] });
    Object.entries(removedRoles).forEach(([project, roles]) => result[project] = { ...result[project], removedRoles: [...roles] });
    return result;
  }



  private _getUserPerResourceRole(roles: RoleNames[]) {
    if (!roles || roles.length === 0) {
      return [];
    }
    return [...this.projectScopeQuery.getAll().reduce((acc, curr) => {
      if (!curr.users || curr.users.length === 0) {
        return acc;
      }
      for (const user of curr.users) {
        for (const role of user.roles) {
          if (!roles.includes(role)) {
            continue;
          }
          acc.add(user.id);
        }
      }
      return acc
    }, new Set<string>())];
  }

  private _getResourceRoles(roles: { [key: string]: RoleNames[] }) {
    if (ObjectHelpers.hasOnlyEmptyValues(roles)) {
      return [];
    }
    return [...new Set<RoleNames>(this.projectQuery.getAll().reduce((acc: RoleNames[], curr) => {
      if (!this.wfQuery.getEntity(curr.workflowId)?.settings?.resourceManagement?.enabled || !this.wfQuery.getEntity(curr.workflowId)?.settings?.resourceManagement?.rules || this.wfQuery.getEntity(curr.workflowId)?.settings?.resourceManagement?.rules.length === 0) {
        return acc;
      }
      for (const rule of this.wfQuery.getEntity(curr.workflowId)?.settings?.resourceManagement?.rules || []) {
        if (rule.managerRoles.some(role => Object.values(roles).flat().includes(role))) {
          acc.push(...rule.resourceRoles);
        }
      };
      return acc;
    }, []))];
  }
}
