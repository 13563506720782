import { Injectable, Injector } from '@angular/core';
import { WsACKCallback } from '@common/api/ack';
import { IAppointment } from '@common/interfaces/appointment';
import { PartialUpdate } from '@common/interfaces/base';
import { ID } from '@common/interfaces/id';
import { IIssue } from '@common/interfaces/issue';
import { IProject } from '@common/interfaces/project';
import { ITag } from '@common/interfaces/tag';
import { Topics } from '@common/interfaces/topics';
import { DeltaInterface, DeltaParameters, UserWorkload, WorkflowDog } from '@common/utils/workflowDog';
import { AppService } from '@ep-om/app.service';
import { SocketIoService } from '@ep-om/core/services/socket-io.service';
import { AuthQuery } from '@ep-om/project/auth/auth.query';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActionService } from '../action/action.service';
import { AppointmentQuery } from '../appointment/appointment.query';
import { CrudService } from '../crudService';
import { EntityInteractionQuery } from '../entityInteraction/entityInteraction.query';
import { interfaceDataQueryMap, interfaceDataServiceMap } from '../interfaceDataMapping';
import { LastUpdateQuery } from '../lastUpdate/lastUpdate.query';
import { LastUpdateService } from '../lastUpdate/lastUpdate.service';
import { ProjectQuery } from '../project/project.query';
import { ProjectService } from '../project/project.service';
import { ProjectScopeQuery } from '../projectScope/projectScope.query';
import { TagService } from '../tag/tag.service';
import { IssueStoreStrategy } from '../updateStoreStrategies';
import { WorkflowQuery } from '../workflow/workflow.query';
import { IssueQuery } from './issue.query';
import { IssueStore, IssueUI } from './issue.store';
import { v4 } from 'uuid';
import { IProjectScopeUsers } from '@common/interfaces/projectScope';

@Injectable({
  providedIn: 'root',
})
export class IssueService extends CrudService<IIssue, IssueStore, IssueQuery> {
  protected topic = Topics.ISSUES;
  newIssueModalIsOpened$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected store: IssueStore,
    public query: IssueQuery,
    protected projectQuery: ProjectQuery,
    private projectService: ProjectService,
    private appService: AppService,
    protected lastUpdateService: LastUpdateService,
    protected lastUpdateQuery: LastUpdateQuery,
    protected socketIoService: SocketIoService,
    protected actionService: ActionService,
    protected issueStrategy: IssueStoreStrategy,
    protected entityInteractionQuery: EntityInteractionQuery,
    protected authQuery: AuthQuery,
    private tagService: TagService,
    private injector: Injector,
    private appointmentQuery: AppointmentQuery,
    private wfQuery: WorkflowQuery,
    private projectScopeQuery: ProjectScopeQuery
  ) {
    super(
      Topics.ISSUES,
      store,
      query,
      actionService,
      issueStrategy,
    );
  }

  firstSync$ = this.updateStoreStrategy.firstSync$;

  setActive(id: ID) {
    this.store.setActive(id);
  }

  localRemoveByIssueAndProject(issue: ID, project: ID) {
    this.store.remove(({ projectId, id }) => projectId === project && id === issue)
  }

  localRemoveNotMineByProject(project: ID, user: ID) {
    this.store.remove(({ projectId, createdBy, assigneeId, reporterId, notificationSubscribers }) => projectId === project && (assigneeId !== user && reporterId !== user && createdBy !== user && !notificationSubscribers.includes(user)));
  }

  getIssuesByActiveProject(): IIssue[] {
    const id = this.projectService.getActive().id;
    return this.query.getIssueByProject(String(id));
  }

  getIssueByProjectIdAndState(projectId: ID, stateId: any) {
    return this.query.getIssueByProject(projectId).filter(issue => issue.stateId === stateId);
  }

  getProjectUsersWorkloadByActiveProject(projectUsers: IProjectScopeUsers[], finalStates: string[]): Map<ID, UserWorkload> {
    let mapWorkLoad = new Map<ID, UserWorkload>();
    // costruisco la mappa con gli utenti attuali del progetto. non la costruisco a partire dalle issue perchè magari qualche utente è associato ad una issue ma non 
    // appartiene più al progetto
    projectUsers.forEach(c => mapWorkLoad.set(c.id, { assignee: 0, reporter: 0 }));
    const project = this.projectService.getActive();
    const projectId = project?.id;
    const issues = this.query.getIssueByProject(String(projectId)).filter(c => !finalStates.includes(c.stateId));
    for (let issue of issues) {
      if (issue.assigneeId || issue.reporterId) {
        if (issue.assigneeId === issue.reporterId) {
          let mappedReporterAssignee = mapWorkLoad.get(issue.assigneeId);
          // se non lo trovo, vuol dire che non è più un utente del progetto
          if (mappedReporterAssignee) {
            mapWorkLoad.set(issue.assigneeId, { assignee: mappedReporterAssignee.assignee + 1, reporter: mappedReporterAssignee.reporter + 1 });
          }
        }
        else {
          if (issue.assigneeId) {
            let mappedAssignee = mapWorkLoad.get(issue.assigneeId);
            // se non lo trovo, vuol dire che non è più un utente del progetto
            if (mappedAssignee) {
              mapWorkLoad.set(issue.assigneeId, { assignee: mappedAssignee?.assignee + 1, reporter: mappedAssignee.reporter });
            }
          }
          if (issue.reporterId) {
            let mappedReporter = mapWorkLoad.get(issue.reporterId);
            // se non lo trovo, vuol dire che non è più un utente del progetto
            if (mappedReporter) {
              mapWorkLoad.set(issue.reporterId, { assignee: mappedReporter.assignee, reporter: mappedReporter.reporter + 1 });
            }
          }
        }
      }
    }
    return mapWorkLoad;
  }

  create(issue: IIssue) {
    const workflowSettings = this.projectQuery.getActiveWorkflowSettings();
    const projectScope = this.projectScopeQuery.getAll()?.find(ps => ps.projectId === issue.projectId);
    const automationForState = WorkflowDog.getAutomationForState(workflowSettings, issue.stateId);
    if (automationForState) {
      const usersWorkload = this.getProjectUsersWorkloadByActiveProject(projectScope?.users ?? [], workflowSettings?.finalStates ?? []);
      const deltaParams: DeltaParameters = { automation: automationForState, issue: issue as IIssue, newStateId: issue.stateId, projectScope, usersWorkload };
      const { deltaIssue, deltaInterfaces } = WorkflowDog.getAutomationDelta(deltaParams)
      issue = { ...issue, ...deltaIssue }
    }
    super.create(issue, { handlerName: 'IssueCreation' });
  }

  update(issue: PartialUpdate<IIssue>) {
    const previousIssue = this.query.getEntity(issue.id);
    if (!!issue.stateId && previousIssue?.stateId !== issue.stateId && !previousIssue.id.includes('new')) {
      const projectScope = this.projectScopeQuery.getAll()?.find(ps => ps.projectId === (issue.projectId || previousIssue.projectId));
      const workflowSettings = this.projectQuery.getWorkflowSettingsByProjectId(issue.projectId || previousIssue.projectId);
      let appointments: IAppointment[] = this.appointmentQuery.getAppointmentsByIssueId(issue.id);
      const automationForState = WorkflowDog.getAutomationForState(workflowSettings, issue.stateId);
      if (automationForState) {
        const usersWorkload = this.getProjectUsersWorkloadByActiveProject(projectScope?.users ?? [], workflowSettings?.finalStates ?? []);
        const deltaParams: DeltaParameters = { automation: automationForState, issue: previousIssue, newStateId: issue.stateId, projectScope, appointments, usersWorkload };
        const { deltaIssue, deltaInterfaces } = WorkflowDog.getAutomationDelta(deltaParams);
        issue = { ...previousIssue, ...issue, ...deltaIssue };
        this.applyAutomationsToInterfaces(issue.id, deltaInterfaces);
      }
    }
    console.log('super update', issue);
    super.update(issue);
  }

  transferIssue(issue: PartialUpdate<IIssue>) {
    const previousIssue = this.query.getEntity(issue.id);
    const projectScope = this.projectScopeQuery.getAll()?.find(ps => ps.projectId === issue.projectId);
    const workflowSettings = this.projectQuery.getWorkflowSettingsByProjectId(issue.projectId || previousIssue.projectId);
    const { deltaIssue, deltaInterfaces } = WorkflowDog.getAutomationDeltaForTransfer(workflowSettings, issue as IIssue, issue.stateId, projectScope);
    issue = { ...issue, ...deltaIssue };
    super.update(issue);
  }

  addNotificationSubscriber(issueId: string): void {
    const issue = this.query.getEntity(issueId);
    const userId = this.authQuery.getLoggedUserId();
    const subcribedUsers = new Array(...issue.notificationSubscribers, userId);
    super.update({ id: issue.id, notificationSubscribers: subcribedUsers });
  }

  removeNotificationSubscriber(issueId: string) {
    const issue = this.query.getEntity(issueId);
    const userId = this.authQuery.getLoggedUserId();
    const filtredId = issue.notificationSubscribers.filter(id => id !== userId);
    super.update({ id: issue.id, notificationSubscribers: filtredId });
  }

  getPendingStatusById$(id: ID): Observable<string> {
    return this.query.ui.selectEntity(id).pipe(
      filter(issue => !!issue),
      map(({ pendingState }) => {
        if (pendingState === 'pending' || this.appService.isOnline === false) {
          return 'processing'
        } else {
          return 'success';
        }
      })
    );
  }

  updateIssueUI(issueUI: Partial<IssueUI>) {
    this.store.ui.update(issueUI.id, issueUI);
  }

  updateManyIssueUi(issueUi: IssueUI[]) {
    this.store.ui.upsertMany(issueUi);
  }

  canSuspend(issue: IIssue, project: IProject) {
    return WorkflowDog.canSuspend(issue, this.wfQuery.getEntity(project?.workflowId)?.settings);
  }

  suspend(issue: PartialUpdate<IIssue>, label: string) {
    this.update({
      id: issue.id,
      suspension: label,
    });
  }

  desuspend(issue: PartialUpdate<IIssue>) {
    this.update({
      id: issue.id,
      suspension: null,
    });
  }

  getUI(issueId: ID): IssueUI {
    return this.query.ui.getEntity(issueId);
  }

  getDayOffset(createdAt: string): number {
    const creationDate: Date = new Date(createdAt);
    const currentDate: Date = new Date();
    const utcCreationDate = Date.UTC(creationDate.getFullYear(), creationDate.getMonth(), creationDate.getDate());
    const utcCurrentDate = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const differenceDays = Math.floor((Math.abs(utcCreationDate - utcCurrentDate)) / (1000 * 60 * 60 * 24));
    return differenceDays;
  }

  trash(id: ID) {
    const tags = this.query.getEntity(id)?.tagIds || [];
    const issueWithTags = this.query.getAll({
      filterBy: issue => !issue.trashedAt && issue.tagIds && issue.id !== id && issue.tagIds.some(tag => tags.includes(tag))
    });
    const tagsToRemove = tags.filter(id => !issueWithTags.some(issue => issue.tagIds.includes(id)));
    if (tagsToRemove.length > 0) {
      tagsToRemove.forEach(tagId => this.tagService.trash(tagId));
    }
    this.update({ id, trashedAt: (new Date()).toISOString() } as PartialUpdate<IIssue & { tags?: ITag[] }>);
  }

  restoreFromTrash(id: ID) {
    this.update({ id, trashedAt: null } as PartialUpdate<IIssue>);
  }

  addTag(tag: ITag | string, issue: PartialUpdate<IIssue>) {
    const issueIsNew = issue.id.startsWith('new_');
    if (typeof tag === 'string') {
      tag = {
        id: `${issueIsNew ? 'new_' : ''}${v4()}`,
        projectId: issue.projectId,
        label: tag
      } as ITag;
      this.tagService.create(tag, { onlyLocal: issueIsNew });
    }
    if (tag.trashedAt) {
      this.tagService.restoreFromTrash(tag.id);
    }
    this.update({
      ...issue,
      tagIds: [...issue.tagIds || [], tag.id],
    });
  }

  removeTagFromIssue(tagId: ID, issue: IIssue) {
    this.update({
      id: issue.id,
      tagIds: issue?.tagIds.filter(tag => tag.toString() !== tagId) || []
    });
    if (issue.id.startsWith('new_')) {
      this.tagService.localRemoveEntity(tagId);
    }
  }

  softRemoveAllTrashedIssues(issuesToRemove: ID[], callback?: WsACKCallback<string[]>) {
    const dto = { ids: issuesToRemove };
    this.actionService.sendMessage(Topics.ISSUES, 'removetrashed', dto, callback);
  }

  applyAutomationsToInterfaces(issueId: string, deltaInterfaces: DeltaInterface[]) {
    for (const mutations of deltaInterfaces) {
      const interfaceService = this.injector.get(interfaceDataServiceMap[mutations.interfaceName]);
      const interfaceQuery = this.injector.get(interfaceDataQueryMap[mutations.interfaceName]);
      const interfaces = interfaceQuery.getByIssueId(issueId);
      for (const int of interfaces) {
        interfaceService.update({ id: int.id, ...mutations.delta });
      }
    }
  }
}
