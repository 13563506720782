import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";

export namespace FormlyValidators {

  export function EmailValidator(control: FormControl): ValidationErrors {
    if(!control.value) return null; // non è responsibilità di questo validatore
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(control.value);
    console.log("EmailValidator",control.value,valid);
    return valid ? null : { 'email-validation': true };
  }
  
  export function EmailValidatorMessage(err, field: FormlyFieldConfig) {
    return `"${
      field.formControl.value
    }" is not a valid Email Address`;
  }

  export function DomainValidator(control: FormControl): ValidationErrors {
    if(!control.value) return null; // non è responsibilità di questo validatore
    const valid = /^[a-z]+([\.-]?[a-z]+)*(\.[a-z]{2,3})+$/.test(control.value);
    return valid ? null : { 'domain-validation': true };
  }
  
  export function DomainValidatorMessage(err, field: FormlyFieldConfig) {
    return `"${
      field.formControl.value
    }" is not a valid Domain`;
  }

  export function UsernameValidator(control: FormControl): ValidationErrors {
    if(!control.value) return null; // non è responsibilità di questo validatore
    return /^([a-z]|[0-9]|[-_]|\.)+$/i.test(control.value) ? null : { 'username-validation': true };
  }
  
  export function UsernameValidatorMessage(err, field: FormlyFieldConfig) {
    return `"${field.formControl.value}" is not a valid username. a-z,0-9,"-","_" `;
  }

  export function fieldMatchValidator(control: AbstractControl) {
    const { password, passwordConfirm } = control.value;
    // avoid displaying the message error when values are empty
    if (!passwordConfirm || !password) {
      return null;
    }
    if (passwordConfirm === password) {
      return null;
    }
    return { fieldMatch: { message: 'Le password non corrispondono.' } };
  }

  export function minValidationMessage(err, field: FormlyFieldConfig) {
      return `Minimo ${err.requiredLength} caratteri.`
  }

  export function maxValidationMessage(err, field: FormlyFieldConfig) {
    return `Massimo ${err.requiredLength} caratteri.`
}

  export function workflowStatesValidator(control: FormControl): ValidationErrors {
    if(control.pristine) {return null;}
    const chosen = control.parent.parent.getRawValue().map(v => v.issueStateId).filter(v => !!v);
    if(chosen.length !== new Set(chosen).size) {
      return { 'unique-state': true };
    }
    return null;
  }

  export function workflowIssueTypesValidator(control: FormControl): ValidationErrors {
    if(control.pristine) {return null;}
    const chosen = control.parent.parent.getRawValue().map(v => v.issueTypeId).filter(v => !!v);
    if(chosen.length !== new Set(chosen).size) {
      return { 'unique-issueType': true };
    }
    return null;
  }

  export function issueTypesInterfacesValidator(control: FormControl): ValidationErrors {
    if(control.pristine) {return null;}
    const chosen = control.parent.parent.getRawValue().map(v => v.interfaceName).filter(v => !!v);
    if(chosen.length !== new Set(chosen).size) {
      return { 'unique-interface': true };
    }
    return null;
  }

  export function phoneValidation(control: FormControl): ValidationErrors {
    if(!control.value) return null; // non è responsibilità di questo validatore 
    const valid = /^(?:\+|\d{1,3})\d{6,14}$/.test(control.value);
    //console.log("phoneValidation",control.value,valid);
    return valid ? null : { 'phone-number': true };
  }
}
