import { FormlySelectOption } from "@common/interfaces/formly";
import { InterfaceName, InterfaceNameValue } from "@common/interfaces/issueTypeInterface";

export namespace ObjectHelpers {

  export const purgeUndefined = (obj: Object) => {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  };
  /**
   * Check if there is at least one property that is not null, undefined or empty string ''
   * Useful to check forms
   */
  export const hasOnlyEmptyValues = (obj: Object) => {
    for (const key in obj) {
      if (
        obj[key] !== undefined &&
        obj[key] !== "" &&
        obj[key] !== null &&
        !(Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        return false;
      }
    }
    return true;
  };

  export const hasProps = (obj: object) => {
    return Object.keys(obj).length > 0;
  };

  export function enumMembersName(): FormlySelectOption[] {
    const result: FormlySelectOption[] = [];
    for (const value in Object.keys(InterfaceName)) {
      if (typeof InterfaceName[value] !== "string") {
        continue;
      }
      result.push({ id: value, name: InterfaceName[Number(value)] });
    }
    return result;
  }

  /**
   * @deprecated
   * @param items 
   * @returns 
   */
  export function interfacesToOptions(
    items: InterfaceNameValue[]
  ): FormlySelectOption[] {
    return items.map((item) => {
      const interfaceName = Object.entries(InterfaceName).find(([key, value]) => value === item);
      return {
        id: interfaceName[1].toString(),
        name: interfaceName[0].toString().replace(/_/g, " "),
      }
    });
  }

  function replace(
    str: string,
    toReplace: string,
    replaceWith: string
  ): string {
    return str.split(toReplace).join(replaceWith);
  }

  export function hasNestedObject(object: Object): boolean {
    let hasNested: boolean = false;
    for (let value of Object.values(object)) {
      if (
        value !== null &&
        typeof value === "object" &&
        Array.isArray(value) === false
      ) {
        hasNested = true;
      }
    }
    return hasNested;
  }

  export function arrEquals(arr1: any[], arr2: any[]): boolean {
    if ((arr1 && !arr2) || (!arr1 && arr2) || (!arr1 && !arr2)) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    if (arr1.length === 0 && arr2.length === 0) {
      return true;
    }
    return arr1.every((el) =>
      arr2.some((el2) => {
        if (typeof el !== typeof el2) {
          return false;
        }
        if (Array.isArray(el) && Array.isArray(el2)) {
          return arrEquals(el, el2);
        }
        if (typeof el === "object") {
          return objEquals(el, el2);
        }
        //el === el2
      })
    );
  }

  export function objEquals(obj1: {}, obj2: {}): boolean {
    if ((obj1 && !obj2) || (!obj1 && obj2) || (!obj1 && !obj2)) {
      return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (typeof obj2[key] !== typeof obj1[key]) {
        return false;
      }
      if (Array.isArray(obj2[key]) && Array.isArray(obj1[key])) {
        return arrEquals(obj2[key], obj1[key]);
      }
      if (typeof obj2[key] === "object") {
        return objEquals(obj2[key], obj1[key]);
      }
      if (obj2[key] !== obj1[key]) {
        return false;
      }
    }
  }

  type Groupable = { [key: string | number]: any };
  /**
   * Raggruppa un array di oggetti in base al valore di una proprietà
   */
  export function groupBy<T extends Groupable>(array: T[], key: keyof T) {
    return array.reduce((acc, curr) => {
      if (typeof curr[key] !== "string") {
        throw new Error("Property value is not a string");
      }
      (acc[curr[key]] = acc[curr[key]] || []).push(curr);
      return acc;
    }, {} as { [key: string | number]: T[] });
  }

  export function encode(d: Date, salt: number, base = 62): string {
    //           |       10|                       36|                       62|             78|    
    const abc = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!?([{_-.:,;@#}])"
    const symbols = abc.split("");
    if (base <= 1) base = 2;
    if (base > symbols.length) base = symbols.length;
    //console.log(abc.substring(0,base));
    let tvms = d.getTime() * 1000 + salt;
    let rv = "";
    while (tvms >= 1) {
      const idx = tvms - (base * Math.floor(tvms / base));
      rv = symbols[idx] + rv;
      tvms = Math.floor(tvms / base);
    }

    return rv;
  }
}

