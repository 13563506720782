import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IID_OLO_DEACTIVATION_VALIDATION } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { IIDolDeactivationQuery } from '../iidOlDeactivation/iidOlDeactivation.query';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDolDeactivationValidationQuery } from './iidOlDeactivationValidation.query';
import { IIDolDeactivationValidationStore } from './iidOlDeactivationValidation.store';


@Injectable({
  providedIn: 'root'
})
export class IIDolDeactivationValidationService extends CrudService<IID_OLO_DEACTIVATION_VALIDATION, IIDolDeactivationValidationStore, IIDolDeactivationValidationQuery> {
  constructor(
    protected store: IIDolDeactivationValidationStore,
    protected query: IIDolDeactivationValidationQuery,
    protected actionService: ActionService,
    private deactivationQuery: IIDolDeactivationQuery,
    protected updateStrategy: IssueEntityStoreStrategy,
    private nzns: NzNotificationService,
    private http: HttpClient
  ) {
    super(
      Topics.IID_OLO_DEACTIVATION_VALIDATION,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

  gisfoRequest(issueId: string): Observable<IID_OLO_DEACTIVATION_VALIDATION & { error: string }> {
    const deactivation = this.deactivationQuery.getByIssueId(issueId);
    if (!deactivation || deactivation.length === 0) {
      this.nzns.create('danger', 'Operazione non effettuata.', 'La dissmissione non è stata trovata...');
      return;
    }
    return this.http.get(`${environment.apiUrl}/api/iid_olo_deactivation_validation/updateGisfoValidation/${issueId}/${deactivation[0].idRisorsa}`)
    .pipe(
      take(1),
      catchError(err => {
        this.nzns.create('danger', err.statusText, err.error?.message);
        throw new Error(err);
      }),
      tap((response: IID_OLO_DEACTIVATION_VALIDATION & { error: string }) => {
        if(response?.error) {
          this.nzns.create('danger', "", response.error);
        }
      })
    )
  }

  deactivateContract(issueId: string): void {
    this.http.post<void>(`${environment.apiUrl}/api/iid_olo_deactivation/deactivateContract/${issueId}`, {})
    .pipe(
      take(1),
      catchError(err => {
        this.nzns.create('danger', err.statusText, err.error?.message);
        return EMPTY;
      })
    ).subscribe();
  }

}
