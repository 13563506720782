import { persistState } from '@datorama/akita';
import * as localForage from 'localforage';
import { debounceTime } from 'rxjs/operators';
import { resetStores } from "@datorama/akita";
import { Topics } from '@common/interfaces/topics';


const debounceTimeMs = 100;

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'OrderManager',
  storeName: 'order-manager_storage',
});

let openRequest = indexedDB.open("OrderManager", 10);
openRequest.onupgradeneeded = function (event) {
  if (event && event.oldVersion < event.newVersion) {
    console.log('resetting stores');
    resetStores();
    localForage.clear();
  }
};

const IID_OL_AcquisitoKoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_AcquisitoKo',
  include: ['IID_OL_AcquisitoKo'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_AccettatoKoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_AccettatoKo',
  include: ['IID_OL_AccettatoKo'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_EspletatoOkStorage = persistState({
  storage: localForage,
  key: 'IID_OL_EspletatoOk',
  include: ['IID_OL_EspletatoOk'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const ActionStorage = persistState({
  storage: localForage,
  key: 'action',
  include: ['action'],
  //preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const AttachmentStorage = persistState({
  storage: localForage,
  key: 'Attachment',
  include: ['Attachment'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const GLOBAL_BATCH_ACTIVITIES_Storage = persistState({
  storage: localForage,
  key: 'GLOBAL_BATCH_ACTIVITIES',
  include: ['GLOBAL_BATCH_ACTIVITIES'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const USER_BATCH_ACTIVITIES_Storage = persistState({
  storage: localForage,
  key: 'USER_BATCH_ACTIVITIES',
  include: ['USER_BATCH_ACTIVITIES'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});
const PROJECT_BATCH_ACTIVITIES_Storage = persistState({
  storage: localForage,
  key: 'PROJECT_BATCH_ACTIVITIES',
  include: ['PROJECT_BATCH_ACTIVITIES'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IssueCommentsStorage = persistState({
  storage: localForage,
  key: 'IssueComments',
  include: ['IssueComments'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const EntityInteractionsStorage = persistState({
  storage: localForage,
  key: 'EntityInteractions',
  include: ['EntityInteractions'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const FilterStorage = persistState({
  storage: localForage,
  key: 'filter',
  include: ['filter'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const NotificationsStorage = persistState({
  storage: localForage,
  key: 'Notifications',
  include: ['Notifications'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_EP_TicketStorage = persistState({
  storage: localForage,
  key: 'IID_EP_Ticket',
  include: ['IID_EP_Ticket'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_EP_AssetStorage = persistState({
  storage: localForage,
  key: 'IID_EP_Asset',
  include: ['IID_EP_Asset'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_ApparatoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Apparato',
  include: ['IID_OL_Apparato'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_ChecklistStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Checklist',
  include: ['IID_OL_Checklist'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_CondominioStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Condominio',
  include: ['IID_OL_Condominio'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_AppuntamentoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Appuntamento',
  include: ['IID_OL_Appuntamento'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_ContrattoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Contratto',
  include: ['IID_OL_Contratto'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_OloRequestStorage = persistState({
  storage: localForage,
  key: 'IID_OL_OloRequest',
  include: ['IID_OL_OloRequest'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_ActivationRequestStorage = persistState({
  storage: localForage,
  key: 'IID_OL_ActivationRequest',
  include: ['IID_OL_ActivationRequest'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_RiferimentiStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Riferimenti',
  include: ['IID_OL_Riferimenti'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_RiferimentiUiStorage = persistState({
  storage: localForage,
  key: 'IID_OL_RiferimentiUi',
  include: ['IID_OL_RiferimentiUi'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_ServizioAggiuntivoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_ServizioAggiuntivo',
  include: ['IID_OL_ServizioAggiuntivo'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IssueStatesStorage = persistState({
  storage: localForage,
  key: 'IssueStates',
  include: ['IssueStates'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IssueStateWorkflowsStorage = persistState({
  storage: localForage,
  key: 'IssueStateWorkflows',
  include: ['IssueStateWorkflows'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IssueTypesStorage = persistState({
  storage: localForage,
  key: 'IssueTypes',
  include: ['IssueTypes'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IssueTypeInterfacesStorage = persistState({
  storage: localForage,
  key: 'IssueTypeInterfaces',
  include: ['IssueTypeInterfaces'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IssueTypeWorkflowsStorage = persistState({
  storage: localForage,
  key: 'IssueTypeWorkflows',
  include: ['IssueTypeWorkflows'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const lastUpdateStorage = persistState({
  storage: localForage,
  key: 'lastUpdate',
  include: ['lastUpdate'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const ProjectsStorage = persistState({
  storage: localForage,
  key: 'Projects',
  include: ['Projects'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
  preStorageUpdate(storeName, state) {
    const {active, ...rest} = state;
    return rest;
  }
});

const ProjectScopesStorage = persistState({
  storage: localForage,
  key: 'ProjectScopes',
  include: ['ProjectScopes'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IssuesUiStorage = persistState({
  storage: localForage,
  key: 'UI/Issues',
  include: ['UI/Issues'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const ProjectsUiStorage = persistState({
  storage: localForage,
  key: 'UI/Projects',
  include: ['UI/Projects'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const UsersStorage = persistState({
  storage: localForage,
  key: 'Users',
  include: ['Users'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const CompaniesStorage = persistState({
  storage: localForage,
  key: 'Companies',
  include: ['Companies'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const WorkflowsStorage = persistState({
  storage: localForage,
  key: 'Workflows',
  include: ['Workflows'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_ClienteStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Cliente',
  include: ['IID_OL_Cliente'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_GenericSuspension = persistState({
  storage: localForage,
  key: 'IID_Generic_Suspension',
  include: ['IID_Generic_Suspension'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
})

const IssuesStorage = persistState({
  storage: localForage,
  key: 'Issues',
  include: ['Issues'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
  preStorageUpdate(storeName, state) {
    const {active, ...rest} = state;
    return rest;
  }
});


const authStorage = persistState({
  //storage: localForage,
  include: ['auth'],
  key: 'authStore',
})

const IID_OL_EspletatoKoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_EspletatoKo',
  include: ['IID_OL_EspletatoKo'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_AnnullatoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Annullato',
  include: ['IID_OL_Annullato'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_CommunicationStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Communication',
  include: ['IID_OL_Communication'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const UiStorage = persistState({
  storage: localForage,
  key: 'ui',
  include: ['ui'],
  //preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
})
const IID_OL_SospensioneStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Sospensione',
  include: ['IID_OL_Sospensione'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_SollecitoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Sollecito',
  include: ['IID_OL_Sollecito'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_RispostaSollecitoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_RispostaSollecito',
  include: ['IID_OL_RispostaSollecito'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_SupportoStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Supporto',
  include: ['IID_OL_Supporto'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_RisoluzioneStorage = persistState({
  storage: localForage,
  key: 'IID_OL_Risoluzione',
  include: ['IID_OL_Risoluzione'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const TagStorage = persistState({
  storage: localForage,
  key: 'Tags',
  include: ['Tags'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const MilestoneStorage = persistState({
  storage: localForage,
  key: 'Milestones',
  include: ['Milestones'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OL_Risorsa = persistState({
  storage: localForage,
  key: 'IID_OL_Risorsa',
  include: ['IID_OL_Risorsa'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_MetaInterfaceStorage = persistState({
  storage: localForage,
  key: 'IID_META_INTERFACE',
  include: ['IID_META_INTERFACE'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const AppointmentStorage = persistState({
  storage: localForage,
  key: 'Appointment',
  include: ['Appointment'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_EP_GisfoSupportStorage = persistState({
  storage: localForage,
  key: Topics.IID_EP_GISFO_SUPPORT,
  include: [`${Topics.IID_EP_GISFO_SUPPORT}`],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_GR_ResourcesStorage = persistState({
  storage: localForage,
  key: 'IID_GR_Resources',
  include: ['IID_GR_Resources'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_GR_TasksStorage = persistState({
  storage: localForage,
  key: 'IID_GR_Tasks',
  include: ['IID_GR_Tasks'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OLO_Deactivation = persistState({
  storage: localForage,
  key: 'IID_OLO_Deactivation',
  include: ['IID_OLO_Deactivation'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OLO_DeactivationValidation = persistState({
  storage: localForage,
  key: 'IID_OLO_DeactivationValidation',
  include: ['IID_OLO_DeactivationValidation'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_OLO_DeactivationPhysic = persistState({
  storage: localForage,
  key: 'IID_OLO_DeactivationPhysic',
  include: ['IID_OLO_DeactivationPhysic'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_Generic_Ko = persistState({
  storage: localForage,
  key: 'IID_Generic_Ko',
  include: ['IID_Generic_Ko'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_Mail_Gtw = persistState({
  storage: localForage,
  key: 'IID_Mail_Gtw_Source',
  include: ['IID_Mail_Gtw_Source'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_Assurance_Ticket = persistState({
  storage: localForage,
  key: 'IID_Assurance_Ticket',
  include: ['IID_Assurance_Ticket'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

const IID_Generic_Ok = persistState({
  storage: localForage,
  key: 'IID_Generic_Ok',
  include: ['IID_Generic_Ok'],
  preStorageUpdateOperator: () => debounceTime(debounceTimeMs),
});

export const localforageProviders = [
  {
    provide: 'persistStorage',
    useValue: IID_Assurance_Ticket,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_Generic_Ok,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_Risorsa,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_GenericSuspension,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_EP_TicketStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_SospensioneStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_CommunicationStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_AppuntamentoStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_AnnullatoStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_EspletatoKoStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_EspletatoOkStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_AccettatoKoStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_AcquisitoKoStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_ClienteStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IssuesStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: authStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: WorkflowsStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: UsersStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: CompaniesStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IssuesUiStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: ProjectsUiStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: ProjectScopesStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: ProjectsStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: lastUpdateStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IssueStateWorkflowsStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IssueTypeInterfacesStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IssueTypeWorkflowsStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IssueTypesStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IssueStatesStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_ServizioAggiuntivoStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_RiferimentiStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_RiferimentiUiStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_OloRequestStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_ActivationRequestStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_ContrattoStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_CondominioStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_ChecklistStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_ApparatoStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_SollecitoStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_RispostaSollecitoStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_SupportoStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OL_RisoluzioneStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_EP_AssetStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: FilterStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: NotificationsStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: EntityInteractionsStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IssueCommentsStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: GLOBAL_BATCH_ACTIVITIES_Storage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: PROJECT_BATCH_ACTIVITIES_Storage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: USER_BATCH_ACTIVITIES_Storage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: AttachmentStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: ActionStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: UiStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: TagStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: MilestoneStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_MetaInterfaceStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: AppointmentStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_EP_GisfoSupportStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_Generic_Ko,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_GR_ResourcesStorage,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_OLO_Deactivation,
    multi: true,

  },
  {
    provide: 'persistStorage',
    useValue: IID_GR_TasksStorage,
    multi: true,
  },
  {
    provide: 'persistStorage',
    useValue: IID_OLO_DeactivationPhysic,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_Generic_Ko,
    multi: true
  },
  {
    provide: 'persistStorage',
    useValue: IID_Mail_Gtw,
    multi: true
  }
];
