import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GisfoPort, GisfoPortState, IPcabNodes } from '@common/interfaces/gisfo';
import { IDeactivationState, IID_OLO_DEACTIVATION_PHYSIC, IID_OL_RIFERIMENTI } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { IUser } from '@common/interfaces/user';
import ColorHash from 'color-hash';
import { BehaviorSubject, EMPTY, Observable, combineLatest } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { UserQuery } from '../user/user.query';
import { IIDolDeactivationPhysicQuery } from './iidOlDeactivationPhysic.query';
import { IIDolDeactivationPhysicStore } from './iidOlDeactivationPhysic.store';


@Injectable({
  providedIn: 'root'
})
export class IIDolDeactivationPhysicService extends CrudService<IID_OLO_DEACTIVATION_PHYSIC, IIDolDeactivationPhysicStore, IIDolDeactivationPhysicQuery> {

  searchRoeValue$: BehaviorSubject<string> = new BehaviorSubject(''); //*subject which is emitted each time when user changes search input of nz-select component
  scrolledToBottom$: BehaviorSubject<number> = new BehaviorSubject(0); //*subject which is emitted each time when user scrolls to the bottom of the ROE list
  portsAreRefreshing$: BehaviorSubject<boolean> = new BehaviorSubject(false); //* whether refresh button is clicked and response is pending
  currentUser: IUser;
  colorHash: ColorHash;

  constructor(
    protected store: IIDolDeactivationPhysicStore,
    protected query: IIDolDeactivationPhysicQuery,
    protected actionService: ActionService,
    private userQuery: UserQuery,
    protected updateStrategy: IssueEntityStoreStrategy,
    private http: HttpClient,
  ) {
    super(
      Topics.IID_OLO_DEACTIVATION_PHYSIC,
      store,
      query,
      actionService,
      updateStrategy,
    );
    this.currentUser = this.userQuery.myUser();
    this.colorHash = new ColorHash();
  }

  private _getRoeHttpRequest(page: number, searchTerm: string) {
    return this.http.get<IID_OL_RIFERIMENTI[]>(`${environment.apiUrl}/api/iid_ol_riferimenti/getRoe/${page}/${searchTerm}`).pipe(take(1));
  }

  /**
   ** 1. Returned Observable<IPcabNodes[]> will be subscribed in case when interface settings have { "fixedRoe": false }
   *? 2. Returned observable makes a new http request to the server in order to fetch new 10 ROE records if exist
   *?     a) each time select input is selected/changed //!(in this case the page number will be reset to 0)
   *?     b) each time user scrolls down to the bottom of the list of options //!(in this case the page will be incremented by 1)
   *? 3. Each time select search input is changed, Returned observable will send request starting from page 0 as was mentiond in 2. a) and then increment page when scroll reaches bottom of the list
   *? 4. Returned observable uses variable-helper {@agregatedRoe} in order to restore previously requested entities (IPcabNodes[]) on scroll, so you should not modify {@agregatedRoe}
  */
   loadRoeFromServer(): Observable<IPcabNodes[]> {
    let agregatedRoe: IPcabNodes[] = [];
    return combineLatest([
      this.searchRoeValue$.pipe(
        distinctUntilChanged((prev, curr) => prev === curr),
        tap(searchTerm => {
          agregatedRoe = []
        })
      ),
      this.scrolledToBottom$
    ]).pipe(
      skip(1),
      debounceTime(200),
      switchMap(([searchTerm, page]) => this._getRoeHttpRequest(page, searchTerm)),
      map(riferimenti => {
        let loadedRoe = riferimenti.flatMap(rf => rf.metadata.buildings.map(b => b.pte));
        const searchTerm = this.searchRoeValue$.value.toLowerCase();
        agregatedRoe = [...agregatedRoe, ...loadedRoe];
        if (searchTerm) {
          agregatedRoe = agregatedRoe.filter(roe => roe.pk_pcab_nodes.toLowerCase().includes(searchTerm) || roe.nome.toLowerCase().includes(searchTerm)) || [];
        }
        return agregatedRoe;
      })
    );
  }

  //* Get all ports using Roe Id from GISFO, check PORTS sync and interface validity 
  gisfoRefreshPortsRequest(roeId: string) {
    return this.http.get<GisfoPort[]>(`${environment.apiUrl}/api/iid_olo_deactivation_physic/refreshPortsGisfo/${roeId}`).pipe(
      take(1),
      catchError(err => {
        console.log(err);
        return EMPTY;
      })
    )
  }

  //* Set up {@syncError} if there are GISFO ports dismissed logically but in OM dismissed physically; Update colors for ports 
  _checkForSyncErrors(ports: GisfoPort[], dismissions: IDeactivationState[]) {
    const filteredPorts = ports.filter(p => !!p.port_name || !!p.pk_fib_ports);
    const coloredCheckedPorts = filteredPorts.map((port, i) => {
      const portSynchStateError = dismissions && port.port_state === GisfoPortState.DISMESSA_LOGICAMENTE 
        && dismissions.find(dismission => dismission.pk_fib_ports === port.pk_fib_ports);
        port.port_name = port.port_name.replace(/(n° |n°|N° |N°)/, '#')
      return {
        ...port,
        syncError: portSynchStateError,
        color: this.colorHash.hex(port.port_name ? port.port_name : port.pk_fib_ports),
      }
    });
    let sortedPorts;
    try {
      sortedPorts = ports.sort((a, b) => {
        const portNumberA = a?.port_name.match(/\d+/);
        const portNumberB = b?.port_name.match(/\d+/);
        if(portNumberA && portNumberA[0] && portNumberB && portNumberB[0]) {
          return parseInt(portNumberB[0]) > parseInt(portNumberA[0]) ? -1 : 1;
        }
        return 0;
      })
    } catch (err) {
      console.log("Can't parse and sorr ports!", err);
      sortedPorts = coloredCheckedPorts;
    }
    return sortedPorts;
  }

  dismissPort(port: GisfoPort, form: FormGroup,  isRoeSelectable: boolean): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/api/gisfo/deactivatePortRoe/${port.pk_fib_ports}`, {})
  }

  //* Recalculates current user deactivated ports number 
  _recalculateUserDismissions(form: FormGroup) {
    const dismissions = form.controls.deactivationPhysic?.value?.dismissions;
    return dismissions && dismissions.length > 0 ? new Set(dismissions.map(dismission => {
      if(dismission.dismissedBy === this.currentUser.id) {
        return dismission.pk_fib_ports
      }
    })).size : 0;
  }

}
