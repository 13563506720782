import { Injectable, InjectionToken } from "@angular/core";
import { Action } from "../action.model";
import { WsACKResponse } from "@common/api/ack";
import { TagService } from "../../tag/tag.service";
import { IIssue } from "@common/interfaces/issue";
import { IssueService } from "../../issue/issue.service";

export interface ResponseHandler {
  success?<T>(action: Action, response: WsACKResponse<T>): Promise<void> | void
  error?<T>(action: Action, response: WsACKResponse<T>): Promise<void> | void
}

@Injectable({
  providedIn: 'any'
})
export class IssueCreationHandler implements ResponseHandler {
  constructor(
    private tagService: TagService,
    private issueService: IssueService,
  ) {}
  success(action: Action, response: WsACKResponse<any>) {
    const issue = action.payload as IIssue;
    const tagsToRemove = issue.tagIds.filter(id => id.startsWith('new'));
    this.tagService.localRemoveEntities(tagsToRemove);
  }
}


export const ISSUE_CREATION = new InjectionToken<ResponseHandler>('IssueCreation');
//export const SEND_CHUNK = new InjectionToken<ResponseHandler>('SendNextChunk');
export const handler_dictionary = {
  'IssueCreation': ISSUE_CREATION,
} as const;

export type HandlerName = keyof typeof handler_dictionary;
