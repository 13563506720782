import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'om-error',
  templateUrl: './om-error.component.html',
  styleUrls: ['./om-error.component.scss']
})
export class OmErrorComponent implements OnInit {
  @Input() control: FormControl;
  triggerError$: Observable<any>;
  
  constructor() {}

  ngOnInit(): void {
    this.triggerError$ = this.control.statusChanges.pipe(
      startWith(this.control.valid ? 'VALID' : 'INVALID'), 
      map(status => (!status || status === 'INVALID'))
    );
  }
}
