import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IIssue } from "@common/interfaces/issue";
import { IIssueState } from "@common/interfaces/issueState";
import { EntityUIQuery, QueryEntity, combineQueries } from "@datorama/akita";
import { combineLatest, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay } from "rxjs/operators";
import { IssuesState, IssueStore, IssuesUIState, IssueUI } from "./issue.store";
import { EntityInteractionQuery } from "../entityInteraction/entityInteraction.query";
import { AuthQuery } from "@ep-om/project/auth/auth.query";
import { compareDateString } from "@ep-om/utils/date";
import { compareAsc } from "date-fns";
@Injectable({
  providedIn: 'root'
})
export class IssueQuery extends QueryEntity<IssuesState> {

  ui: EntityUIQuery<IssuesUIState>;
  all$ = this.select()
  active$ = this.selectActive();

  actions$ = this.selectEntityAction()

  //history: EntityStateHistoryPlugin;

  constructor(
    protected store: IssueStore,
    protected entityInteractionQuery: EntityInteractionQuery,
    protected authQuery: AuthQuery,
  ) {
    super(store);
    this.createUIQuery();
    //this.history = new EntityStateHistoryPlugin(this)
    //this.history.undo
  }

  issueNotSuspendedWithUidByProjectAndStatus$(projectId, stateId: string): Observable<(IIssue & { ui: IssueUI })[]> {
    return combineLatest([
      this.selectAll({
        filterBy: [
          issue => issue.projectId === projectId,
          issue => issue.stateId === stateId,
          issue => !issue.suspension,
          issue => !issue.trashedAt
        ]
      }),
      this.ui.select()
    ]).pipe(
      map(([issues, uiIssues]) => issues.map(
        issue => ({
          ...issue,
          ui: uiIssues[issue.id]
        })
      )),
      shareReplay({ refCount: true, bufferSize: 1 }),
    )
  }

  issueSuspendedByProject$(projectId): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: [
        issue => issue.projectId === projectId,
        issue => !!issue.suspension,
        issue => !issue.trashedAt
      ]
    }).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  getAllTrashed() {
    return this.getAll({
      filterBy: issue => !!issue.trashedAt && !issue.deletedAt
    })
  }

  newsById$(issueId: ID) {
    return combineQueries([
      this.selectEntity(issueId).pipe(filter(issue => !!issue)),
      this.entityInteractionQuery.selectByIssueId$(issueId),
      this.authQuery.userId$,
    ]).pipe(
      map(([issue, interactions, userId]) => {
        if (issue.lastUpdatedBy === userId) {
          return false;
        }
        return (
          !interactions.some(interaction => interaction.userId === userId && compareAsc(new Date(issue.updatedAt), new Date(interaction.time)) < 1  )
        )
      }),
      distinctUntilChanged()
    );
  }

  newsCountByProjectId$(projectId: ID): Observable<number> {
    return combineQueries([
      this.selectAll({
        filterBy: issue => issue.projectId === projectId && !issue.trashedAt
      }),
      this.entityInteractionQuery.selectAll(),
      this.authQuery.userId$
    ]).pipe(
      map(([issues, interactions, userId]) => {
        return issues.filter(issue => {
          if (issue.lastUpdatedBy === userId) {
            return false;
          }
          return !interactions.some(interaction => interaction.issueId === issue.id && interaction.userId === userId && compareAsc(new Date(issue.updatedAt), new Date(interaction.time)) < 1  )
        }).length;
      })
    );
  }

  issueById$(issueId: ID) {
    return this.selectEntity(issueId);
  }

  issueByProjectId$(projectId: ID): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: issue => issue.projectId === projectId && !issue.trashedAt
    });
  }

  getIssueByProject(projectId: ID) {
    return this.getAll({
      filterBy: issue => issue.projectId === projectId && !issue.trashedAt
    });
  }

  issueDeletedByProjectId$(projectId: ID): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: issue => issue.projectId === projectId && !!issue.trashedAt
    });
  }

  lastIssuePosition = (state: IIssueState): number => {
    return this.getAll({
      filterBy: issue => issue.stateId === state.id
    }).length;
  }

  selectByProjectIdAndStateId$(projectId: ID, stateId: ID): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: issue => issue.projectId === projectId && !issue.trashedAt && issue.stateId === stateId && !issue.suspension
    });
  }

  selectByProjectIdAndMilestoneId$(projectId: ID, milestoneId: ID): Observable<IIssue[]> {
    return this.selectAll({
      filterBy: issue => issue.projectId === projectId && !issue.trashedAt && issue.milestoneId === milestoneId && !issue.suspension
    });
  }
}
