import { Injectable } from '@angular/core';
import { IID_MAIL_GTW_SOURCE } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDMailGtwStore } from './iidMailGtw.store';
import { IIDMailGtwQuery } from './iidMailGtw.query';
@Injectable({
  providedIn: 'root'
})
export class IIDMailGtwService extends CrudService<IID_MAIL_GTW_SOURCE, IIDMailGtwStore, IIDMailGtwQuery> {
  constructor(
    protected store: IIDMailGtwStore,
    protected query: IIDMailGtwQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_MAIL_GTW_SOURCE,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
