import { Injectable } from '@angular/core';
import { IID_GENERIC_OK } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { IID_Generic_OkQuery as IID_Generic_OkQuery } from './genericOk.query';
import { IID_Generic_OkStore as IID_Generic_OkStore } from './genericOk.store';
@Injectable({
  providedIn: 'root'
})
export class IID_Generic_OkService extends CrudService<IID_GENERIC_OK, IID_Generic_OkStore, IID_Generic_OkQuery> {
  constructor(
    protected store: IID_Generic_OkStore,
    protected query: IID_Generic_OkQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_GENERIC_OK,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

}
