import { Inject, Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject, of, combineLatest, merge } from "rxjs";
import { debounceTime, delay, filter, pairwise, repeatWhen, shareReplay, takeUntil } from "rxjs/operators";
import { Socket, Manager } from 'socket.io-client'
import { Topics, FilterType } from '@common/interfaces/topics';
import { logger } from "@ep-om/utils/logger";
import { AppService } from "@ep-om/app.service";
import { ID } from "@common/interfaces/id";
import { AuthService } from "@ep-om/project/auth/auth.service";


//export type FilterType<T> = { [P in keyof T]?: T[P] } | (() => { [P in keyof T]?: T[P] });


@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  private manager: Manager = new Manager(`${this.address}`, {
    autoConnect: false,
    transports: ['websocket'],
    //extraHeaders: { token: this.authService.authQuery.getValue().accessToken },
    reconnection: false,
    query: {
      token: this.authService.authQuery.getValue().accessToken,
    }
  });
  public socket: Socket;
  public sockets: { [key in Topics]?: Socket } = {};
  public connected$ = new BehaviorSubject<boolean>(false);
  private _disconnected$ = new Subject<void>();
  private _reconnect$ = new Subject<boolean>();

  constructor(
    @Inject('SOCKET_IO_ADDRESS') private address: string,
    private appService: AppService,
    private authService: AuthService,
  ) {

    const token = this.authService.authQuery.getValue().accessToken;
    if (token) this.init();

    //osservo i cambiamenti di valore del token
    this.authService.authQuery.accessToken$.pipe(pairwise()).subscribe((pair) => {
      if (!pair[0] && pair[1]) { //from no token to one
        this.init();
      }
      if (pair[0] && !pair[1]) { //from token to no
        this.disconnect();
      }
    })

    /// Need to reconnect while offline
    this._reconnect$.pipe(
      debounceTime(5000),
      // takeUntil(combineLatest([
      //   this.authService._loggingOut$,
      //   //this.authService.authQuery.accessToken$.pipe(filter(token => !token)),
      //   this.connected$.pipe(filter(connected => connected === true))
      // ])),
      // repeatWhen(() => this.connected$.pipe(filter(x => x === false)))
    ).subscribe(() => {
      if (this.authService.isLoggedIn()) {
        this.connect();
      }
    });

  }


  public uponConnection$ = this.connected$.pipe(filter(x => x === true), debounceTime(300), shareReplay({refCount: true, bufferSize: 1}));

  public stopOnDisconnectionPipe<T>() {
    return takeUntil<T>(this._disconnected$);
  }

  public repeatOnConnectionPipe<T>() {
    return repeatWhen<T>(() => this.connected$.pipe(filter(x => x === false)));
  }

  listenEntity<T>(topic: Topics, filters?: FilterType): Observable<{ topic: Topics, data: T[] }> {
    //filters = typeof filters === "function" ? filters() : filters;
    return new Observable<{ topic: Topics, data: T[] }>(observer => {
      this.sockets[topic].on('entity_updates', (data: T[]) => {        
        observer.next({ topic, data });
      });
      this.sockets[topic].emit(`entity_subscribe`, { filters });
      return () => {
        this.sockets[topic].emit(`entity_unsubscribe`, 'entity');
        this.sockets[topic].off(`entity_updates`);
      }
    })
  }

  listenResource<T>(topic: Topics, resource: string, filters?: any): Observable<{topic: Topics, data: T}> {
    return new Observable<{topic: Topics, data: T}>(observer => {
      this.sockets[topic].on(`${resource}_updates`, (data: T) => {
        observer.next({ topic, data });
      });
      this.sockets[topic].emit(`${resource}_subscribe`, { filters });
      return () => {
        this.sockets[topic].emit(`${resource}_unsubscribe`, resource);
        this.sockets[topic].off(`${resource}_updates`);
      }
    })
  }

  remove(topic: Topics, id: ID) {
    this.socket.emit(`${topic}.remove`, id);
  }

  init() {
    if (this.socket)
      this.connect()
    else
      this.socketSetup();
  }

  connect() {
    if (!this.socket.disconnected) {
      return;
    }
    this.manager.opts = {
      ...this.manager.opts,
      query: { token: this.authService.authQuery.getValue().accessToken },
      //extraHeaders: { token: this.authService.authQuery.getValue().accessToken },
    }
    this.socket.open();
  }

  disconnect() {
    console.log('[SOCKET-IO] - Forcing disconnection');
    this.socket.disconnect();
    Object.values(this.sockets).forEach(s => s.disconnect());
    this._disconnected$.next();
    this.connected$.next(false);
  }

  socketSetup() {

    Object.values(Topics).forEach(topic => {
      this.sockets[topic] = this.manager.socket(`/${topic}`)
      this.sockets[topic].on('connect_error', (message) => {
        console.log('[SOCKET] - error trying to connect to socket', topic, message);
      });
    });

    this.socket = this.manager.socket('/');

    this.socket.on('connect', async () => {
      console.log("[SOCKET] - CONNECTED")
      await this.appService.check();
      Object.values(this.sockets).forEach(socket => socket.connect());
      this.connected$.next(true)
    });
    this.socket.on('reconnect', async () => {
      await this.appService.check();
    })
    this.socket.on('disconnect', async () => {
      console.log("[SOCKET] - DISCONNECTED")
      this.appService.setOffline();
      Object.values(this.sockets).forEach(socket => socket.off())
      this._disconnected$.next();
      this.connected$.next(false);
      this._reconnect$.next(true);
      this.appService.updateMaintab(false);
    });
    this.socket.on('reconnecting_attempt', () => { logger.log('trying to reconnect...') });
    this.socket.on('connect_error', async (message) => {
      console.log("SOCKET CONNECT ERROR", message.name, message.message, message);
      const accessToken = this.authService.authQuery.getAccessToken();
      const expires = accessToken ? this.authService.getExpirationFromToken(accessToken) : new Date('1995-12-17T03:24:00');
      let timeout = expires.getTime() - Date.now() - (10 * 1000) / 1000;
      const amIOnline = await this.appService.amIOnline();
      if (
        amIOnline === true &&
        !this.appService.mainTab$.value &&
        timeout < 5
      ) {
        console.log('[SOCKET] - I am the main tab');
        //
        // attraverso il broadcast channel implementare una request per capire se c'è qualche altra scheda che vuole diventare master
        //
        this.appService.updateMaintab(true);
      }
      this._reconnect$.next(true);

    });

    this.connect();
  }
}
