import { ProjectScopeState, ProjectScopeStore } from './projectScope.store';
import { Injectable } from '@angular/core';
import { ItemPredicate, QueryEntity } from '@datorama/akita';
import { ProjectQuery } from '../project/project.query';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectScopeQuery extends QueryEntity<ProjectScopeState> {
  all$ = this.selectAll();

  constructor(protected store: ProjectScopeStore, private projectQuery: ProjectQuery) {
    super(store);
  }

  active$ = this.projectQuery.selectActiveId().pipe(
    switchMap(id => {
      return this.selectEntity(item => item.projectId === id);
    })
  );

  getRoleByProjectAndUser = (projectId: string, userId: string) => {
    return this.getEntity(projectId).users.find(u => u.id === userId);
  }
}
