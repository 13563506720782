import { Injectable } from '@angular/core';
import { IID_OL_RISORSA } from '@common/interfaces/interfaceData';
import { ActionService } from '../action/action.service';
import { Topics } from '@common/interfaces/topics';
import { CrudService } from '../crudService';
import { IssueEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDolRisorsaStore } from './iidOlRisorsa.store';
import { IIDolRisorsaQuery } from './iidOlRisorsa.query';
import { WsACKCallback } from '@common/api/ack';
@Injectable({
  providedIn: 'root'
})
export class IIDolRisorsaService extends CrudService<IID_OL_RISORSA, IIDolRisorsaStore, IIDolRisorsaQuery> {
  constructor(
    protected store: IIDolRisorsaStore,
    public query: IIDolRisorsaQuery,
    protected actionService: ActionService,
    protected updateStrategy: IssueEntityStoreStrategy) {
    super(
      Topics.IID_OL_RISORSA,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

  makeReservation(issueId: string, callback?: WsACKCallback<IID_OL_RISORSA>) {
    this.actionService.sendMessage(Topics.IID_OL_RISORSA, 'reservation', { issueId }, callback);
  }

  completeReservation(issueId: string, callback?: WsACKCallback<IID_OL_RISORSA>) {
    this.actionService.sendMessage(Topics.IID_OL_RISORSA, 'complete_reservation', { issueId }, callback);
  }

  releaseSignal(issueId: string, callback?: WsACKCallback<IID_OL_RISORSA>) {
    this.actionService.sendMessage(Topics.IID_OL_RISORSA, 'release_signal', { issueId }, callback);
  }

  refreshFromGisfo(issueId: string, callback?: WsACKCallback<IID_OL_RISORSA>) {
    this.actionService.sendMessage(Topics.IID_OL_RISORSA, 'refresh', { issueId }, callback);
  }

}
