import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VERSION } from '@common/version';
import { IClientSettings } from '@common/clientSettings';
import { defaultFeatureDisplay, FeatureDisplay as IFeatureDisplay, FeatureEnvironmentAssociation } from '@common/features';
import { resetStores } from '@datorama/akita';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SysMessage } from '@common/interfaces/sysMessage';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export const BACKEND_VERSION_LOCALSTORAGE_KEY = '__BACKEND_VERSION';
export const ASSETS_SETTINGS_LOCALSTORAGE_KEY = '__ASSETS_SETTINGS';
export const MAINTENANCE_LOCALSTORAGE_KEY = '__MAINTENANCE';
export const CLIENT_ID_LOCALSTORAGE_KEY = '__CLIENT_UID';

const STORE = window.localStorage;
const HEARTBEAT_MS = 5000;

@Injectable({
  providedIn: 'root',
})
export class AppService {

  readonly FRONTEND_VERSION: string = '1.218';

  private _online$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public online$: Observable<boolean> = this._online$.asObservable();
  private _versionOk$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public versionOk$: Observable<boolean> = this._versionOk$.asObservable();

  private _features$: BehaviorSubject<IFeatureDisplay> = new BehaviorSubject<IFeatureDisplay>(defaultFeatureDisplay);
  public features$: Observable<IFeatureDisplay> = this._features$.asObservable();

  private _settings$ = new BehaviorSubject<IClientSettings>(null);
  public settings$ = this._settings$.asObservable();

  public mainTab$ = new BehaviorSubject(null);
  private http: HttpClient;

  constructor(private httpBackend: HttpBackend, private route: Router, private nzns: NzNotificationService) {

    console.log("===============");
    console.log("VERSION",this.FRONTEND_VERSION);
    console.log("===============");

    this.http = new HttpClient(httpBackend);
    this.settings();
    this.check();
    let ts = new Date().getTime();
    this.mainTab$.subscribe(main => console.log('[MAIN TAB]', main));
    // setInterval(() => {
    //   if ((new Date().getTime() - ts) > 2 * HEARTBEAT_MS) {
    //     console.log('Wake-up from sleep detected');
    //     window.location.reload();        
    //   }
    //   ts = new Date().getTime(); 
    // }, HEARTBEAT_MS);
  }

  public async settings() {
    try {
      const rv = await this.http.request<IClientSettings>('GET', `${environment.apiUrl}/assets/customizable/settings.json`).toPromise();
      STORE.setItem(ASSETS_SETTINGS_LOCALSTORAGE_KEY, JSON.stringify(rv));
      this._settings$.next(rv);
    }
    catch (err) {
      console.error('cannot download settings', err);
    }
    await  this.features();
    window.document.title = this.SETTINGS.appTitle;
  }

  public async amIOnline(): Promise<boolean> {
    try {
      await this.http.request<typeof VERSION>('GET', `${environment.apiUrl}/api`).toPromise();
    } catch (err) {
      return false;
    }
    return true;
  }

  public async check() {

    try {
      console.time("[CHECK]");
      const rv = await this.http.request<typeof VERSION>('GET', `${environment.apiUrl}/api`).toPromise();
      console.timeEnd("[CHECK]");
      STORE.setItem(BACKEND_VERSION_LOCALSTORAGE_KEY, JSON.stringify(rv))
      this._online$.next(true);
    }
    catch (err) {
      this._online$.next(false);
    }
    
    if (!!this.BACKEND_VERSION) {
      const frontendMajorVersion = parseInt(this.FRONTEND_VERSION.split(".")[0])
      const frontendMinorVersion = parseInt(this.FRONTEND_VERSION.split(".")[1])
      const minFrontendMajorVersion = parseInt(this.BACKEND_VERSION.MIN_FRONTEND_VERSION.split(".")[0])
      const minFrontendMinorVersion = parseInt(this.BACKEND_VERSION.MIN_FRONTEND_VERSION.split(".")[1])
      this._versionOk$.next(frontendMajorVersion >= minFrontendMajorVersion && frontendMinorVersion >= minFrontendMinorVersion);
    }

  }

  public setOffline() {
    this._online$.next(false);
  }

  offlineMessage() {
    this.nzns.warning("OFFLINE","In questo momento sei offline, l'operatività di base è preservata. Alcune oparazioni importanti non sono disponibili mentre sei offline.",{ nzDuration:10000 });
  }

  public async features() {
    const rv = this.SETTINGS;
    if (!rv) {
      await this.settings();
      return this.features();
    }
    if (rv.enabledFeatures) {
      const currentFeatures = this._features$.getValue();
      for (const key in rv.enabledFeatures) {
        switch (rv.enabledFeatures[key]) {
          case true:
            currentFeatures[key] = FeatureEnvironmentAssociation[key] || "DISABLED";
            break;
          default:
            currentFeatures[key] = "DISABLED"
        }
      }
      this._features$.next(currentFeatures);
    }
  }

  get isVersionOk(): boolean {
    return this._versionOk$.value;
  }

  get isOnline(): boolean {
    return this._online$.value;
  }

  get BACKEND_VERSION(): typeof VERSION {
    const rv = STORE.getItem(BACKEND_VERSION_LOCALSTORAGE_KEY)
    return !!rv ? JSON.parse(rv) : null;
  }

  get SETTINGS(): IClientSettings {
    const rv = STORE.getItem(ASSETS_SETTINGS_LOCALSTORAGE_KEY)
    return !!rv ? JSON.parse(rv) : null;
  }

  get FEATURES(): IFeatureDisplay {
    return this._features$.getValue();
  }

  public setMaintenance(state: boolean): void {
    localStorage.setItem(MAINTENANCE_LOCALSTORAGE_KEY, JSON.stringify(state));
    resetStores({ exclude: ['ui'] });
    this.route.navigate(['maintenance']);
  }

  public async requestMaintenance(): Promise<boolean> {
    let inMaintenance = await this.http.request<boolean>('GET', `${environment.apiUrl}/api/sysMessage/maintenance`).toPromise();
    this.setMaintenance(inMaintenance);
    return false;
  }

  public updateMaintab(bool: boolean) {
    // if (this.mainTab$.value === true) {
    //   return;
    // }
    this.mainTab$.next(bool);
  }

  clientMessageManager(msg:SysMessage) {
    switch(msg.payload.type) {
      default:
        console.log("Gestore del ClientMessage non presente");
        break;
    }
  }

  async clientRequestManager(rq:SysMessage):Promise<SysMessage> {
    let rv:SysMessage = null;
    switch(rq.payload.type) {
      default:
        console.log("Gestore del ClientRequest non presente");
        break;
    }
    return rv;
  }
  
  
}
